@import "./../../index.scss";
$graph-side: 670px;

#canvas-background {
  position: relative;
  width: $graph-side;
  height: $graph-side;
  border-radius: $button-radius;
  background: white;
  user-select: none;
  box-shadow: 0px 0px 1px #333;
  canvas {
    flex-shrink: 1;
    height: 100%;
    width: 100%;
  }
  .quadrant {
    font-family: Georgia, "Times New Roman", Times, serif;
    padding: 20px 30px;
    position: absolute;
    font-size: 2rem;
    color: #ddd;
  }
  #I {
    top: 0;
    right: 0;
  }
  #II {
    top: 0;
    left: 0;
  }
  #III {
    bottom: 0;
    left: 0;
  }
  #IV {
    bottom: 0;
    right: 0;
  }
}
@media screen and (max-height: 691px) {
  #canvas-background {
    width: 566px;
    height: 566px;
  }
}
@media screen and (max-width: 1097px) {
  #canvas-background {
    width: 566px;
    height: 566px;
  }
}
