$button-radius: 6px;
$theme: rgb(0, 129, 235);
$theme-light: rgb(196, 218, 233);
$theme-dark: rgb(0, 88, 139);
$control-height: 36px;
$button-backgroud: rgb(243, 243, 243);

* {
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
body {
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: rgb(208, 210, 219);
}
main {
  display: flex;
  flex-direction: row;
  gap: 36px;
  padding: 16px;
}
.controls {
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
label {
  cursor: pointer;
}
button {
  border: 1px solid grey;
  background: $button-backgroud;
  padding-left: 12px;
  padding-right: 12px;
  height: $control-height;
  border-radius: $button-radius;
  cursor: pointer;
  &:hover {
    border-color: $theme-dark;
    background: $theme-light;
  }
}
div[role="group"] {
  display: flex;
  button {
    border-left-width: 0.5px;
    border-right-width: 0.5px;
    border-radius: 0;
    &[data-state="on"] {
      background-color: $theme;
      border-width: 1px;
      border-color: $theme-dark;
      color: white;
    }
    &:first-of-type {
      border-top-left-radius: $button-radius;
      border-bottom-left-radius: $button-radius;
      border-left-width: 1px;
    }
    &:last-of-type {
      border-top-right-radius: $button-radius;
      border-bottom-right-radius: $button-radius;
      border-right-width: 1px;
    }
  }
}
button[role="switch"] {
  height: 26px;
  width: 42px;
  position: relative;
  border: 1px solid #ffffff00;
  border-radius: 99px;
  background-color: grey;
  &[data-state="checked"] {
    background-color: $theme;
  }
  cursor: pointer;
  span {
    position: absolute;
    border-radius: 99px;
    top: 2px;
    left: 2px;
    height: 20px;
    width: 20px;
    transition: all 0.3s;
    background-color: white;
    &[data-state="checked"] {
      left: 18px;
    }
  }
}
input {
  height: $control-height;
  padding-left: 8px;
  border-radius: $button-radius;
  border: 1px solid grey;
  width: 80px;
}
.preset-angles {
  display: flex;
  button {
    flex-grow: 1;
  }
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switch {
  gap: 8px;
  height: $control-height;
  // padding: 0px 8px 0px 11px;
  // background-color: $button-backgroud;
  border-radius: $button-radius;
  font-size: 14px;
}
#angle {
  border-radius: $button-radius;
  background-color: white;
  padding: 24px;

  h1 {
    font-size: xx-large;
    font-weight: normal;
  }
}
.identities-row {
  display: flex;
  gap: 16px;
}
